import React, { useEffect } from "react"
import { useState } from "react"
import { FetchFromBackoffice } from "../../../utils/request"
import { ToggleButton } from "@mui/material"


interface ViewFilterItem {
    id:string
    name:string
    selected:boolean
}

export const ViewFilterList = ({viewName}:{viewName:string}) => {
const [items, setItems] = useState<Array<ViewFilterItem>>([])

useEffect(() => {
    FetchFromBackoffice<Array<ViewFilterItem>>(`/nodes/view/${viewName}`)
        .then(m => setItems(m))
},[viewName])

const saveItem = (nodeId:string, select:boolean) => {
    FetchFromBackoffice<{id:string, selected:boolean}>(`/nodes/view/${viewName}/id/${nodeId}/set/${select}`)
        .then(r => setItems(c => c.map(i => i.id === r.id ? {...i, selected: r.selected}: i)))
}

    return(
        <table>
            {items.map(item => (<tr><td><ToggleButton size="small" value="checked" selected={item.selected} onClick={() => saveItem(item.id, !item.selected)} >{item.name}</ToggleButton></td></tr>))}
        </table>
    )
}