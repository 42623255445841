import { Box, Divider, IconButton, Link, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from 'react-router-dom'
import { AnyAction, Dispatch } from "redux";
import { fetchCustomer } from "../../../../state/actions/customer";
import { StoreState } from "../../../../state/configureStore";
import { connectionStateColors, padding, runningStateColors } from "../../../../theme";
import { findNodeForId } from "../../../../utils/customerUtils";
import { HorizontalSpacer } from "../../../atoms/spacer";
import Tag from "../../../atoms/tag";
import { DataLoadingText } from "../../../atoms/text/dataLoadingText";
import { TextTitle } from "../../../atoms/text/titles";
import { MachineState } from "../../../../state/reducers/machine";
import { lastActivityToReadableTime } from "../../../../utils/text";
import { DateTime } from "luxon";
import { Close } from '@mui/icons-material'

const LinkLabel = ({ isMobile, path, linkText, label }: { isMobile: boolean, path: string, linkText: string | undefined, label: string }) => {
  return (
    <Typography variant={isMobile ? 'body2' : 'body1'}>
      <>{label}</>
      {linkText ? (

        <Link component={RouterLink}
          to={path}>{linkText}</Link>
      ) : (
        '-'
      )
      }
    </Typography>


  )
}

type PopupProps = { machine: MachineState, mapPopup: any }

const MachineMapPopup = (state: PopupProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>) => {
  const [fetchedOwnerName, setFetchedOwnerName] = useState<string>('')
  const [fetchedStoreName, setFetchedStoreName] = useState<string>('')

  useEffect(() => {
    const foundOwner = findNodeForId(state?.customers?.customers, state.machine?.nodeId)
    if (!foundOwner || !foundOwner.parents) {
      state.fetchCustomer(state.machine?.nodeId)
    } else if (foundOwner) {
      setFetchedStoreName(foundOwner?.name)
      setFetchedOwnerName(foundOwner?.parents?.length ? foundOwner.parents[0].name : '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.machine, JSON.stringify(state.customers)])

  if (!state.machine) return null

  const isMobile = state.dimensions.device === "mobile"
  const isDesktop = state.dimensions.device === "desktop"

  return (
    state.loadingState === 'loading' ? <DataLoadingText showSpinner text={'Loading data ...'} />
      : <Box style={{
        padding: padding * 1.5,
        display: 'flex',
        flexDirection: 'column',
      }}>
        <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <TextTitle title={state.machine.name || ""} />
          {isDesktop ? null : <IconButton sx={{ alignSelf: 'flex-start', padding: 0 }} onClick={() => state.mapPopup.remove()}>
            <Close />
          </IconButton>}
        </Box>
        <Divider /><HorizontalSpacer />

        <LinkLabel isMobile={isMobile} path={`/customers/${state.machine.parents[0].id}`} linkText={fetchedOwnerName} label="Owned by: " />
        <HorizontalSpacer /><Divider /><HorizontalSpacer />
        <LinkLabel isMobile={isMobile} path={`/customers/${state.machine.nodeId}`} linkText={fetchedStoreName} label="Located at: " />

        <HorizontalSpacer /><Divider /><HorizontalSpacer />

        <Typography variant={isMobile ? 'body2' : 'body1'}>{'Total cycles run: ' + state.machine.cyclesCount}</Typography>
        {/* <HorizontalSpacer />
        <Typography variant='body1'>{'Last activity: ' + lastActivityToReadableTime(state.machine.connectionState, DateTime.fromISO(state.machine.lastActivityTime), DateTime.now())}</Typography> */}

        <HorizontalSpacer /><Divider />
        {/* <HorizontalSpacer />

        <Tag
          title={'The machine is currently ' + state.machine.runningState + '.'}
          color={state.machine.runningState === 'running' ? runningStateColors.running : runningStateColors.idle}
        /> */}
        <HorizontalSpacer />
        <Tag
          title={'The machine is ' + (state.machine.connectionState === 'Connected' ? 'connected' : 'not connected') + '.'}
          color={state.machine.connectionState === 'Connected' ? connectionStateColors.connected : connectionStateColors.not_connected}
          titleFontSize={isMobile ? 14 : undefined}
        />

        <HorizontalSpacer /><Divider /><HorizontalSpacer />

        <Link fontSize={isMobile ? 14 : '1rem'} style={{ paddingTop: padding }} component={RouterLink} to={`/machines/${state.machine.serialId}`}>{'Go to machine page'}</Link>
      </Box>
  );
};

const mapStateToProps = (state: StoreState) => ({
  loadingState: state.customer_fetching,
  customers: state.customers,
  dimensions: state.dimensions
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  fetchCustomer: (id) => dispatch(fetchCustomer(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(MachineMapPopup)

