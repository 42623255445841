import MachineActionTypes, { MachineOnlineHistoryType } from '../actionTypes/machine'
import { FetchingReducer, SavingReducer } from './general'
import { LogItemMessage, MachineStateChanged, RecipeActionMessage } from '../sagas/app'
import { Location } from '../../utils/map'
import { capitalizeFirstLetter } from '../../utils/text'
import { SortOn } from '../../pages/machines/logTable'
import { DateTime } from 'luxon'

export type MachineReducer = MachineState[]

export type MachineLog = {
  telematicsId: string
  timestamp: string
  record: string
  level: string
  source: string
}

export type MachineLogsReducer = { [serialId: string]: MachineLogs }

export type MachineLogs = {
  records: MachineLog[],
  total: number,

  sortedBy?: SortOn,
  sortedDesc?: boolean
  countPerPage?: number,
  page?: number
}

export type MachineState = {
  cyclesCountToday: number,
  serialId: string,
  name?: string,
  lastActivityTime: string,
  connectionState: string,
  nodeId: string,
  nodeName: string,
  cyclesCount: number,
  location?: Location,
  runningState: 'running' | 'idle',
  parents: { id: string, levelIndex: number, levelName: string, name: string }[],
  levelName: string,
  viewNames?: string[],
  alertTypes?: string[],
  alertIds?: string[],
  monitored?: boolean,
  remoteUpdateEnabled?: boolean
}

export type MachineOnlineHistoryReducer = { [serialId: string]: MachineOnlineHistoryType }

export type MachineOnlineState = {
  connectionState: string,
  isOnline: boolean, 
  startAt: string,
  endAt: string,
  duration: string
}

export type MachineTwinReducer = { [serialId: string]: MachineTwin }

export type MachineTwin = {
  connectionState: string,
  serialId: string,
  lastActivityTime: string,
  properties: {
    desired: any[],
    reported: any[]
  }
}

export type SignalRLogReducer = { [serialId: string]: SignalRMessage[] }
export type SignalRMessage = RecipeActionMessage | LogItemMessage | MachineStateChanged

export const initialMachineState: MachineReducer = []

export const initialTestSequenceState = {
  loading: false,
  error: null,
  successMachineId: null
};

export function test_sequence(state = initialTestSequenceState, action: MachineActionTypes) {
  switch (action.type) {
    case 'START_TEST_SEQUENCE':
      return {
        ...state,
        loading: true,
        error: null,
        successMachineId: null
      };
    case 'TEST_SEQUENCE_STARTED':
      return {
        ...state,
        loading: false,
        successMachineId: action.serialId
      };
    case 'STARTING_TEST_SEQUENCE_FAILED':
      return {
        ...state,
        loading: false,
        error: action.error
      };
    default:
      return state;
  }
}

export function machines(state: MachineReducer = initialMachineState, action: MachineActionTypes): MachineReducer {
  switch (action.type) {
    case 'MACHINES_FETCHED':
      return action.data
    case 'MACHINE_RENAMED': {
      const index = state.findIndex(d => d.serialId === action.serialId)
      const updatedMachines = [...state]
      if (index > -1) {
        updatedMachines[index] = {
          ...updatedMachines[index],
          name: action.newName
        }
      }
      return updatedMachines
    }
    case 'RECIPE_ACTION_MESSAGE_RECEIVED': {
      const index = state.findIndex(d => d.serialId === action.message.machineId)

      const isToday = DateTime.fromISO(action.message.timestamp).hasSame(DateTime.local(), "day");

      const updatedMachines = [...state]
      if (index > -1) {
        updatedMachines[index] = {
          ...updatedMachines[index],
          cyclesCount: action.message.action === "started" ? updatedMachines[index].cyclesCount + 1: updatedMachines[index].cyclesCount,
          cyclesCountToday: isToday && action.message.action === "started" ? updatedMachines[index].cyclesCountToday + 1 : updatedMachines[index].cyclesCountToday,
          runningState: action.message.action === "started" ? 'running' : 'idle'
        }
      }

      return updatedMachines
    }
    case 'MACHINE_STATE_CHANGED_RECEIVED': {
      const index = state.findIndex(d => d.serialId === action.message.machineId)
      const updatedMachines = [...state]
      if (index > -1) {
        updatedMachines[index] = {
          ...updatedMachines[index],
          connectionState: action.message.currentState
        }
      }
      return updatedMachines
    }
    case 'MACHINE_DEACTIVATED': {
      const index = state.findIndex(d => d.serialId === action.payload.serialNumber)
      const updatedMachines = [...state]
      if (index > -1) {
        updatedMachines[index] = {
          ...updatedMachines[index],
          monitored: false,
        }
      }
      return updatedMachines
    }
    case 'MACHINE_ACTIVATED': {
      const index = state.findIndex(d => d.serialId === action.payload.serialNumber)
      const updatedMachines = [...state]
      if (index > -1) {
        updatedMachines[index] = {
          ...updatedMachines[index],
          monitored: true,
        }
      }
      return updatedMachines
    }
    default:
      return state
  }
}

export const initialMachineFetchingState: FetchingReducer = 'ready'

export function machine_fetching(state: FetchingReducer = initialMachineFetchingState, action: MachineActionTypes): FetchingReducer {
  switch (action.type) {
    case 'FETCH_MACHINES':
      return 'loading'
    case 'FETCHING_MACHINES_FAILED':
      return 'failed'
    case 'MACHINES_FETCHED':
      return 'ready'
    default:
      return state
  }
}

export const initialMachineLogsReducer: MachineLogsReducer = {}
export function machine_logs(state: MachineLogsReducer = initialMachineLogsReducer, action: MachineActionTypes): MachineLogsReducer {
  switch (action.type) {
    case 'FETCH_MACHINE_LOGS': {
      const newState = { ...state }
      newState[action.serialId] = {
        ...newState[action.serialId],
        sortedBy: action.sortBy,
        sortedDesc: action.desc,
        page: action.page,
        countPerPage: action.countPerPage
      }
      return newState
    }
    case 'MACHINE_LOGS_FETCHED': {
      const newState = { ...state }
      newState[action.serialId] = { ...newState[action.serialId], ...action.data }
      return newState
    }
    case 'MACHINE_LOG_MESSAGE_RECEIVED': {
      const newRecord: MachineLog = {
        record: action.message.message,
        level: capitalizeFirstLetter(action.message.level?.toLowerCase()),
        source: action.message.source,
        telematicsId: action.message.machineId,
        timestamp: action.message.timestamp
      }

      const existingLogs = state[action.message.machineId]
      let newLogs: MachineLogs
      if (!existingLogs?.sortedBy || existingLogs.sortedBy === 'timestamp') {
        if (!existingLogs?.sortedDesc || existingLogs.sortedDesc) {
          // put log in the very beginning of list, if we are on the first page
          if (existingLogs?.page && existingLogs.page > 0) {
            newLogs = { ...existingLogs, total: existingLogs.total + 1 }
          }
          else {
            let newRecords = [newRecord]
            if (existingLogs?.records) newRecords = newRecords.concat(...existingLogs.records)
            if (existingLogs?.countPerPage) {
              newRecords = newRecords.slice(0, existingLogs.countPerPage)
            }
            newLogs = {
              ...existingLogs,
              records: newRecords,
              total: (existingLogs?.total ?? 0) + 1
            }
          }
        }
        else {
          // if enough space or we are at the last page, save log in the very end of list
          const currentPageIsLastPage = existingLogs.page === undefined || existingLogs.countPerPage === undefined || !existingLogs.total ||
            (existingLogs.countPerPage * (existingLogs.page + 1) >= existingLogs.total)
          const enoughSpaceForLog = existingLogs.countPerPage === undefined || existingLogs.records?.length < existingLogs.countPerPage

          let newRecords: MachineLog[] = []

          if (enoughSpaceForLog) {
            newRecords = existingLogs.records
            newRecords.push(newRecord)
            newLogs = {
              ...existingLogs,
              records: newRecords,
              total: existingLogs.total + 1
            }
          }
          // if last page and not enough space: need to push one log out and increase total
          else {
            if (currentPageIsLastPage) {
              newRecords = existingLogs.records.slice(1)
              newRecords.push(newRecord)
              newLogs = {
                ...existingLogs,
                records: newRecords,
                total: existingLogs.total + 1
              }
            }
            // if not last page and not enough space: just increase total
            else {
              newLogs = {
                ...existingLogs,
                total: existingLogs.total + 1
              }
            }
          }
        }
      }
      else {
        newLogs = {
          ...existingLogs,
          total: existingLogs.total + 1
        }
      }

      const newState = { ...state }
      newState[action.message.machineId] = newLogs

      return {
        ...newState
      }
    }

    default: return state
  }
}

export function machine_logs_fetching(state: FetchingReducer = initialMachineFetchingState, action: MachineActionTypes): FetchingReducer {
  switch (action.type) {
    case 'FETCH_MACHINE_LOGS':
      return 'loading'
    case 'FETCHING_MACHINE_LOGS_FAILED':
      return 'failed'
    case 'MACHINE_LOGS_FETCHED':
      return 'ready'
    default:
      return state
  }
}

export const initialMachineOnlineHistoryReducer: MachineOnlineHistoryReducer = {}
export function machine_online_history(state: MachineOnlineHistoryReducer = initialMachineOnlineHistoryReducer, action: MachineActionTypes): MachineOnlineHistoryReducer {
  switch (action.type) {
    case 'MACHINE_ONLINE_HISTORY_FETCHED': {
      const newState = { ...state };
      if (action.data) {
        newState[action.data.id] = action.data;
      }
      return newState;
    }
    default: return state
  }
}

export function machine_online_history_fetching(state: FetchingReducer = initialMachineFetchingState, action: MachineActionTypes): FetchingReducer {
  switch (action.type) {
    case 'FETCH_MACHINE_ONLINE_HISTORY':
      return 'loading'
    case 'FETCHING_MACHINE_ONLINE_HISTORY_FAILED':
      return 'failed'
    case 'MACHINE_ONLINE_HISTORY_FETCHED':
      return 'ready'
    default:
      return state
  }
}

export const initialMachineSavingState: SavingReducer = 'ready'
export function machine_saving(state: SavingReducer = initialMachineSavingState, action: MachineActionTypes): SavingReducer {
  switch (action.type) {
    case 'RENAME_MACHINE': return 'saving'
    case 'RENAMING_MACHINE_FAILED': return 'failed'
    case 'MACHINE_RENAMED': return 'ready'
    default: return state
  }
}

export function orphaned_machines_fetching(state: FetchingReducer = initialMachineFetchingState, action: MachineActionTypes): FetchingReducer {
  switch (action.type) {
    case 'FETCH_ORPHANED_MACHINES': return 'loading'
    case 'FETCHING_ORPHANED_MACHINES_FAILED': return 'failed'
    case 'ORPHANED_MACHINES_FETCHED': return 'ready'
    default: return state
  }
}

export const initialOrphanedMachinesState: MachineState[] = []
export function orphaned_machines(state: MachineState[] = initialOrphanedMachinesState, action: MachineActionTypes): MachineState[] {
  switch (action.type) {
    case 'ORPHANED_MACHINES_FETCHED': {
      return action.data
    }
    default: return state
  }
}

export const initialSignalRLogsState: SignalRLogReducer = {}
export function signal_r_logs(state: SignalRLogReducer = initialSignalRLogsState, action: MachineActionTypes): SignalRLogReducer {
  switch (action.type) {
    case 'RECIPE_ACTION_MESSAGE_RECEIVED': {
      const newState = { ...state }
      if (Object.keys(newState).find(k => k === action.message.machineId)) {
        newState[action.message.machineId].push(action.message as RecipeActionMessage)
      }
      else {
        newState[action.message.machineId] = [action.message]
      }
      return newState
    }
    case 'MACHINE_LOG_MESSAGE_RECEIVED': {
      const newState = { ...state }
      if (Object.keys(newState).find(k => k === action.message.machineId)) {
        newState[action.message.machineId].push({...action.message, level: capitalizeFirstLetter(action.message.level?.toLowerCase())} as LogItemMessage)
      }
      else {
        newState[action.message.machineId] = [{...action.message, level: capitalizeFirstLetter(action.message.level?.toLowerCase())} as LogItemMessage]
      }
      return newState
    }
    case 'MACHINE_STATE_CHANGED_RECEIVED': {
      const newState = { ...state }
      if (Object.keys(newState).find(k => k === action.message.machineId)) {
        newState[action.message.machineId].push(action.message as MachineStateChanged)
      }
      else {
        newState[action.message.machineId] = [action.message as MachineStateChanged]
      }
      return newState
    }
    default: return state
  }
}