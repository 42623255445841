import React, { useState } from 'react';
import { Box, Button, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { startTestSequence } from '../../state/actions/machine';
import { padding } from '../../theme';
import { TextTitle } from '../../components/atoms/text/titles';
import { StoreState } from '../../state/configureStore';

interface TestSequenceProps {
  SwSerialId: string;
}

interface TestSequenceState {
  loading: boolean;
  error: string | null;
  successMachineId: string | null;
}

const TestSequence: React.FC<TestSequenceProps> = ({ SwSerialId }) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const testSequenceState = useSelector((state: StoreState) => state.test_sequence as TestSequenceState);

  console.log("SwSerialId",SwSerialId)

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleStartTest = () => {
    try {
      dispatch(startTestSequence(SwSerialId));
      handleCloseModal();
    } catch (err) {
      console.error('Failed to start test sequence:', err);
    }
  };

  return (
    <Box sx={{ padding: padding }}>
      <Typography variant="h6" gutterBottom>
        Start Test Sequence
      </Typography>
      <Typography variant="body1" gutterBottom>
        Click the button below to start a test sequence for this machine.
      </Typography>
      
      {testSequenceState.error && (
        <Typography color="error" sx={{ mt: 1, mb: 1 }}>
          Failed to start test sequence, please try again.
        </Typography>
      )}
      
      {testSequenceState.successMachineId === SwSerialId && (
        <Typography color="success.main" sx={{ mt: 1, mb: 1 }}>
          Test sequence started successfully!
        </Typography>
      )}
      
      <Box sx={{ mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenModal}
          disabled={testSequenceState.loading || !SwSerialId}
        >
          Start Test Sequence
        </Button>
      </Box>

      <Dialog 
        open={isModalOpen} 
        onClose={handleCloseModal} 
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            boxShadow: 'rgb(113 122 131 / 11%) 0px 7px 30px 0px',
          },
        }}
      >
        <DialogTitle>
          <TextTitle title="Confirm Test Sequence" noPadding />
        </DialogTitle>
        <DialogContent>
          <Box sx={{ p: 2 }}>
          <Typography variant="body1" paragraph>
            The machine will run a test for 10 minutes. 
            </Typography>
            <Typography variant="body1" fontWeight="bold">Procedure:</Typography>
            <Typography variant="body1"> Leather <br />
            wait 90 seconds <br />
            Suede <br />
            wait 90 seconds <br />
            Synthetic<br />
            wait 90 seconds <br />
            Textile. <br />
          </Typography>
          <br/>
            <Typography variant="body1" color="#ff0000"fontWeight="bold">Important!</Typography>
            <Typography variant="body1" paragraph> During this time, do not use the machine. </Typography>
          <Typography variant="body1" paragraph>
            Do you want to proceed with starting the test sequence?
          </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleCloseModal} 
            color="primary"
          >
            Cancel
          </Button>
          <Button 
            onClick={handleStartTest} 
            variant="contained" 
            color="primary"
            disabled={testSequenceState.loading}
          >
            {testSequenceState.loading ? 'Starting...' : 'Start Test'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TestSequence;
