import React from "react"


export const DecimalRounded = ({value, decimals = 2, fill = true}:{value:number|undefined, decimals?: 0 | 1 | 2, fill?: boolean}) => {

    if(value !== undefined && value !== null) {
        let rounded = (Math.round(value * 100) / 100).toFixed(decimals)

        return(<>{rounded}</>)
        }
}