import React, { useEffect, useState } from "react"
import Page from "../../../components/molecules/page"
import { Box, Tab } from "@mui/material"
import { NodeSummaryView } from "./nodeSummary"
import { NodeSummary } from "./types"
import { MonthStepControl } from "../../../components/atoms/navigations"
import { FetchFromBackoffice } from "../../../utils/request"
import { StoresList } from "./storesList"
import { ChildrenList } from "./childrenList"
import { useSearchParams } from "react-router-dom"
import { ViewFilterComponent } from "../../../components/organisms/view-filter"
import { RoleContainer } from "../../../components/atoms/role-container"
import { PageContent, PageSection } from "../../../components/atoms/layouts"
import { TabContext, TabList, TabPanel } from "@mui/lab"

const RefreshData = (nodeId: string | null, year: number, month: number, update: (node: NodeSummary | undefined) => void) => {
  FetchFromBackoffice<NodeSummary>(`customer/kpis?year=${year}&month=${month}`)
  .then(node => {
    if(nodeId) {
      update(node.children.find(child => nodeId === child.id))
    } else {
      update({...node, name: "All"})
    }
  })

}


export const KpiReportsPage = () => {
    const today = new Date()
    const [tab, setTab] = useState<string>("1")
    const [year, setYear] = useState<number>(today.getFullYear())
    const [month, setMonth] = useState<number>(today.getMonth())
    const [node, setNode] = useState<NodeSummary|undefined>()
    const [search] = useSearchParams()
    const storeId = search.get('storeId')
    
    useEffect(() => {
      RefreshData(storeId, year, month, setNode)
    }, [year, month, storeId])

    return (
        <Page
          hideBreadcrumbs={false}
          title={node?.name}
          >

        <RoleContainer showForRoles={["Developer"]}>
          <div style={{display:"flex", flexDirection: "row-reverse"}}>
            {storeId ? null : (<ViewFilterComponent viewName="report_sales_kpis_view" refresh={() => RefreshData(storeId, year, month, setNode)} />)}
          </div>
        </RoleContainer>

        <PageContent>
          <PageSection>
            <MonthStepControl year={year} month={month} onChangeMonth={(year, month) => {setYear(year); setMonth(month)}} />
          </PageSection>            

          <PageSection>
            <NodeSummaryView node={node} />
          </PageSection>

          <PageSection>
            <TabContext value={tab}>
              <Box>
                <TabList onChange={(e,v) => setTab(v)}>
                  <Tab value="1" label="Companies" />
                  <Tab value="2" label="Top Stores" />
                  <Tab value="3" label="Low Stores" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <ChildrenList showLink={!storeId} children={node?.children} />
              </TabPanel>
              <TabPanel value="2">
                <StoresList title="Top Stores" stores={node?.topStores} />
              </TabPanel>
              <TabPanel value="3">
                <StoresList title="Low Stores" stores={node?.lowStores} />
              </TabPanel>
            </TabContext>
          </PageSection>
        </PageContent>
      </Page>
  )
}


