import { configureStore, ThunkAction, Action, Store } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';
import { createLogger } from 'redux-logger';
import rootReducer from './reducers';
import { rootSaga } from './sagas';
import { User } from '@auth0/auth0-react';
import thunk from 'redux-thunk';
import { initialUserFetchingState, initialUserSavingState, initialUserState } from './reducers/user';
import { initialMachineFetchingState, initialMachineLogsReducer, initialMachineOnlineHistoryReducer, initialMachineSavingState, initialMachineState, initialOrphanedMachinesState, initialSignalRLogsState, initialTestSequenceState } from './reducers/machine';
import { initialCustomerFetchingState, initialCustomerInvoiceFetchingState, initialCustomerInvoiceReducer, initialCustomerSavingErrorState, initialCustomerSavingState, initialCustomersFetchingState, initialCustomersState } from './reducers/customer';
import { initialAppSettingsState, initialDimensionsState, initialSignalRConnectionState } from './reducers/app';
import { initialMapReducer } from './reducers/map';
import { initialMachineErrors } from './reducers/machine-errors';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';

// Logger middleware configuration
const logger = createLogger({
  collapsed: true,
});

// Saga middleware setup
const sagaMiddleware = createSagaMiddleware();

// Define the initial state
const startupState: StoreState = {
  authenticated_user: null,
  users: initialUserState,
  machines: initialMachineState,
  machine_fetching: initialMachineFetchingState,
  machine_saving: initialMachineSavingState,
  orphaned_machines: initialOrphanedMachinesState,
  orphaned_machines_fetching: initialMachineFetchingState,
  user_fetching: initialUserFetchingState,
  user_saving: initialUserSavingState,
  customers: initialCustomersState,
  customers_fetching: initialCustomersFetchingState,
  customer_fetching: initialCustomerFetchingState,
  customer_saving: initialCustomerSavingState,
  customer_saving_error: initialCustomerSavingErrorState,
  machine_logs: initialMachineLogsReducer,
  machine_logs_fetching: initialMachineFetchingState,
  machine_online_history: initialMachineOnlineHistoryReducer,
  machine_online_history_fetching: initialMachineFetchingState,
  signal_r_connection: initialSignalRConnectionState,
  signal_r_logs: initialSignalRLogsState,
  map_state: initialMapReducer,
  customer_invoice: initialCustomerInvoiceReducer,
  customer_invoice_fetching: initialCustomerInvoiceFetchingState,
  settings: initialAppSettingsState,
  machine_errors: initialMachineErrors,
  dimensions: initialDimensionsState,
  test_sequence: initialTestSequenceState
};

// Configure the store
const configureAppStore = (initialState: StoreState = startupState) => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({         
        serializableCheck: {
        ignoredActions: ['persist/PERSIST']
      }, }).concat(
        thunk,
        sagaMiddleware,
        process.env.NODE_ENV === 'development' ? logger : []
      ),
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== 'production',
  });
  
  sagaMiddleware.run(rootSaga);

  // Persist the store
  const persistor = persistStore(store);
//   let currentStore: Store<StoreState, StoreAction>

//   currentStore = store;

  return { store, persistor };
};

// Define types for the application state and dispatch
export const getStore = () => store
export const getPersistor = () => persistor
export type StoreState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, StoreState, unknown, Action<string>>;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Export the configured store
export const { store, persistor } = configureAppStore();
