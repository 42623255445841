import { Dashboard, Store, AdUnits, Group, Settings, Description } from '@mui/icons-material'
import { SvgIconTypeMap } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import * as React from 'react'
import Customer from './pages/customers/customer'
import Customers from './pages/customers/customers'
import {Reports} from './pages/reports/reports'
import Machine from './pages/machines/machine'
import Machines from './pages/machines/machines'
import LoginCallback from './pages/loginCallback'
import { UsersPage } from './pages/users/usersPage'
import DashboardPage from './pages/dashboard/dashboard'
import { InvoicePage } from './pages/customers/invoice/invoicePage'
import PriceModelView from './pages/customers/pricemodel'
import {SettingsPage} from './pages/settings/settings'
import { LogoutLandingPage } from './pages/logoutCallback'
import { OrganizationUserPage } from './pages/users/organization_user'
import { KpiReportsPage } from './pages/reports/kpi-reports'

type Route = {
  url: string
  name: string
  navbar: boolean
  component: React.ComponentType
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>,
  public?: boolean
}

const routes: Route[] = [
  {
    url: '/login_success',
    name: 'Login',
    navbar: false,
    component: LoginCallback
  },
  {
    url: '/logout',
    name: 'Logout',
    navbar: false,
    component: LogoutLandingPage,
    public: true
  },
  {
    url: '/',
    name: 'Dashboard',
    navbar: true,
    component: DashboardPage,
    icon: Dashboard
  },
  {
    url: '/reports',
    name: 'Report',
    navbar: true,
    component: () => <Reports />,
    icon: Description
  },
  {
    url: '/reports/kpi',
    name: 'KPIs',
    navbar: false,
    component: KpiReportsPage
  },
  {
    url: '/customers',
    name: 'Customers',
    navbar: true,
    component: Customers,
    icon: Store
  },
  {
    url: `/customers/:customerId/invoice`, // * to include the deeper path structures customerId will be the id of the base node
    name: 'Customer Invoice',
    navbar: false,
    component: InvoicePage,
  },
  {
    url: `/customers/:customerId/price-model`, // * to include the deeper path structures customerId will be the id of the base node
    name: 'Price Model',
    navbar: false,
    component: PriceModelView,
  },
  {
    url: `/customers/:customerId/*`, // * to include the deeper path structures customerId will be the id of the base node
    name: 'Customer',
    navbar: false,
    component: Customer,
  },
  {
    url: '/machines',
    name: 'Machines',
    navbar: true,
    component: Machines,
    icon: AdUnits
  },
  {
    url: '/machines/:serialId',
    name: 'Machine details',
    navbar: false,
    component: Machine
  },
  {
    url: '/users',
    name: 'Users',
    navbar: true,
    component: UsersPage,
    icon: Group
  },
  {
    url: '/orguser/:userId',
    name: 'Organisation Users',
    navbar: false,
    component: OrganizationUserPage,
  },
  {
    url: '/settings',
    name: 'Settings',
    navbar: true,
    component: SettingsPage,
    icon: Settings 
  }
]

export default routes