import { useAuth0, User, withAuthenticationRequired } from '@auth0/auth0-react'
import { connect } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import './App.css'
import theme, { breakpoints } from "./theme";
import Navbar from './navbar'
import routes from './routes'
import {
  Backdrop,
  CircularProgress,
} from '@mui/material'
import { Box, useTheme } from '@mui/system'
import { sec } from './utils/auth0Helper'
import React, { Fragment, useEffect, useState } from 'react'
import { AnyAction, Dispatch } from 'redux'
import { loggedIn } from './state/actions/user'
import { appEnded, appStarted } from './state/actions/app';

const ProtectedRoute = ({ component }: { component: React.ComponentType }) => {
  const ProtectedComponent = withAuthenticationRequired(component, {
    onRedirecting: () =>
      <Backdrop
        sx={{ color: 'primary', zIndex: theme => theme.zIndex.drawer + 1, backgroundColor: theme.palette.primary.light }}
        open={true}
      >
        <Box sx={{ display: 'block', textAlign: 'center' }}>
          <CircularProgress color='primary' />
        </Box>
      </Backdrop>
  })

  return <ProtectedComponent />
}

const App = (state: ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>) => {
  const auth0 = useAuth0()
  const theme = useTheme()

  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {
    if (auth0.isAuthenticated) {
      sec.setAccessTokenSilently(auth0.getAccessTokenSilently)
      if (auth0.user) {
        state.loggedIn(auth0.user)
        setIsLoggedIn(true)
        sec.getAccessTokenSilently().then(token =>
          state.appStarted(token, (auth0.user!).email)
        )
        return
      }
    }
    setIsLoggedIn(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth0])

  useEffect(() => {
    return () => { state.appEnded() }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box sx={{ 
      display: 'flex',
      height: '100vh',
      width: '100vw',
      /* , backgroundColor: 'secondary.light' */
      [breakpoints.renderNavbarOnTop]: { flexDirection: 'column' }
      }}>
      <Fragment>
        { isLoggedIn ? <Navbar /> : false}
        <Box sx={{ 
          width: '100vw',
          position: 'relative',
          display: 'flex',
          flex: 1,
          overflow: 'scroll'
          }}>
          <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            {/* <Header /> */}
            <Routes>
            {routes.map((route) => {
              // If we don't check for isLoggedIn, the page gets rendered too early on reloading the page and tries to fetch data before auth0 is ready to return the token
              const isAllowedToSee = route.public || isLoggedIn
              return <Route 
                path={route.url}
                element={route.public ? <route.component /> : <ProtectedRoute component={isAllowedToSee ? route.component : Fragment} />}
                key={route.url} />
              })
            }
            </Routes>
          </Box>
        </Box>
      </Fragment>        
    </Box>
  )
}

const mapStateToProps = () => ({
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  loggedIn: (user: User) => dispatch(loggedIn(user)),
  appStarted: (token, email) => dispatch(appStarted(token, email)),
  appEnded: () => dispatch(appEnded())
})

export default connect(mapStateToProps, mapDispatchToProps)(App)