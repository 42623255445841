import React, { ReactNode } from "react";
import { Box } from "@mui/material";


export interface ViewBoxProperties {
    extend?: object
}

const ViewBox = ({
    extend,
    children
  }: ViewBoxProperties & {children: ReactNode}) => {

    return(
        <Box sx={{overflow: 'hidden', ...extend}}>
            {children}
        </Box>
    )
  }


export default ViewBox;