import { Box } from "@mui/material"
import React from "react"
import { DecimalRounded } from "../../../components/atoms/text"
import { StoreSummary } from "./types"


export const StoresList = ({title, stores}:{title:string, stores:Array<StoreSummary> | undefined}) => {

    return (
      <Box>
        <Box>
          <Box><span>{title}</span></Box>
        </Box>
        <Box>
          <table>
            <thead>
              <tr>
                <th style={{color: "white", backgroundColor: "black"}}>Store</th>
                <th style={{color: "white", backgroundColor: "black"}}>Treatments</th>
                <th style={{color: "white", backgroundColor: "black"}}>Per day</th>
              </tr>
            </thead>
            <tbody>
              {
                stores?.map((store, idx) => (
                <tr key={idx}>
                  <td style={{borderRight: "1px solid black"}}>{store.name}</td>
                  <td align="right" style={{borderRight: "1px solid black"}}>{store.treatmentsPeriod}</td>
                  <td align="right" style={{borderRight: "1px solid black"}}><DecimalRounded value={store.treatmentsPerDay} /></td>
                </tr>
                ))
              }
            </tbody>
          </table>
        </Box>
      </Box>
    )
  }