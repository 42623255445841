import React, { useState } from "react";
import { KnownErrorCodes } from "../../../common-types";
import { Tooltip } from "@mui/material";
import { toAgo } from "../../../functions";

export interface IErrorItem {
    serialId: string,
    machineName: string,
    errorType: KnownErrorCodes | undefined,
    text: string,
    reportedAt: Date,
    blocking:boolean
}

const ErrorItemView = ({Error} : {Error: IErrorItem}) => {
    const [open, setOpen] = useState<boolean>(false)
    const [ago, setAgo] = useState<string>()

    const handleOpen = () => {
        setAgo(toAgo(Error.reportedAt))
        setOpen(true)
    }

    return (
        <div style={{ backgroundColor: '#224937', color:'white', padding:'3px 6px'}}>
            <Tooltip open={open} title={ago} onOpen={handleOpen} onClose={() => setOpen(false)}>
                <span>{Error.text}</span>
            </Tooltip>
        </div>
    )
}

export const ErrorListSmall = ({Errors}:{Errors:Array<IErrorItem>}) => {
    return(
        <div>
            {Errors.map((i, idx) => <ErrorItemView key={idx} Error={i} />)}
        </div>
    )
}

