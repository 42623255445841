import React from "react"
import { DecimalRounded } from "../../../components/atoms/text"
import { NavLink } from "react-router-dom"

interface ChildInfo {
    id?: string | undefined
    name: string
    treatmentsPeriod: number
    treatmentsPerDay: number
}

export const ChildrenList = ({children, showLink = false}:{showLink?:boolean, children:Array<ChildInfo>|undefined}) => {
    return (
        <table>
            <thead>
                <tr>
                    <th style={{color: "white", backgroundColor: "black"}}>Name</th>
                    <th style={{color: "white", backgroundColor: "black"}}>Treatments</th>
                    <th style={{color: "white", backgroundColor: "black"}}>Treatments / Day</th>
                </tr>
            </thead>
            <tbody>
                {
                    children?.map((child, idx) => (
                        <tr key={idx}>
                            <td style={{borderRight: "1px solid black"}}>
                                {showLink ?
                                    (<NavLink to={`/reports/kpi?storeId=${child.id}`}>{child.name}</NavLink>):
                                    (<>{child.name}</>)}
                            </td>
                            <td align="right" style={{borderRight: "1px solid black"}}>{child.treatmentsPeriod}</td>
                            <td align="right" style={{borderRight: "1px solid black"}}><DecimalRounded value={child.treatmentsPerDay} /></td>
                        </tr>
                    ))
                }
            </tbody>
        </table>
    )
}