import React from "react"
import { useState } from "react"
import { ViewFilterButton } from "./button"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { ViewFilterList } from "./ViewFilterList"



export const ViewFilterComponent = ({viewName, refresh = () => {}}:{viewName:string, refresh?: () => void}) => {
    const [dialogOpen, setDialogOpen] = useState<boolean>(false)


    return (
        <>
            <ViewFilterButton open={dialogOpen} onOpen={() => setDialogOpen(true)} onClose={() => setDialogOpen(false)} />
            <Dialog open={dialogOpen}>
                <DialogContent>
                    <ViewFilterList viewName={viewName} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {setDialogOpen(false); refresh()}} >Close</Button>
                </DialogActions>
            </Dialog>
        </>
    )

}