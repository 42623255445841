import React, { ReactNode } from "react"
import { useAuth0 } from '@auth0/auth0-react'
import { SystemRoles } from "."

const Intersect = <T,>(arr1: Array<T>, arr2: Array<T>): T[] => {
    const set2 = new Set(arr2)
    return arr1.filter(item => set2.has(item))
}

export const RoleContainer = ({showForRoles, children}:{showForRoles:Array<SystemRoles>, children:ReactNode}) => {
    const {user} = useAuth0()
    const roles = user?.["backoffice.expro.technology/globalRoles"] || [];

    if(Intersect(roles, showForRoles).length > 0)
        return (
            <>
                {children}
            </>
        )
}

