import React, { useEffect, useReducer, useState } from "react"
import { InvoiceHeader } from "./invoiceHeader"
import { InvoiceSpecifications } from "./invoiceSpecifications"
import { InvoiceResponse } from "./types"
import { useParams } from "react-router-dom"
import { DateTime } from "luxon"
import { useQueryBackend } from "../../../utils/requestHelpers"
import { Box, Paper } from "@mui/material"
import { HorizontalSpacer } from "../../../components/atoms/spacer"
import { padding } from "../../../theme"
import '../../../css/table.css'

const INVOICE_INIT = 'INVOICE_INIT';
const INVOICE_SUCCESS = 'INVOICE_SUCCESS';
const INVOICE_FAILED = 'INVOICE_FAILED';

type InitAction = {
    type: typeof INVOICE_INIT
}

type SuccessAction = {
    type: typeof INVOICE_SUCCESS
    data: InvoiceResponse | undefined
}

type FailedAction = {
    type: typeof INVOICE_FAILED
    error: unknown
}

type InvoiceAction = InitAction | SuccessAction | FailedAction

type InvoiceState = {
    data: InvoiceResponse | undefined,
    isLoading: boolean,
    error: unknown
}

const initialState: InvoiceState = { 
    isLoading: false,
    data: undefined,
    error: undefined
}

const invoiceReducer = (state: InvoiceState, action: InvoiceAction): InvoiceState => {
    switch (action.type){
        case INVOICE_INIT:
            return {
                isLoading: true,
                data: undefined,
                error: undefined
            }

        case INVOICE_SUCCESS:
            return {
                isLoading: false,
                data: action.data,
                error: undefined
            }

        case INVOICE_FAILED:
            return {
                isLoading: false,
                data: undefined,
                error: action.error
            }
    }

}
export const InvoicePage = () => {
    const [invoiceState, dispatchInvoice] = useReducer(invoiceReducer, initialState)
    const {customerId} = useParams()
    const [year, setYear] = useState<number>(new Date().getMonth() === 0 ?  new Date().getFullYear() -1 : new Date().getFullYear()  ) 
    const [month, setMonth] = useState<number>(new Date().getMonth() === 0 ? 12 : new Date().getMonth() ) 
    const queryApi = useQueryBackend("Backoffice")
    useEffect(() => {
        if(customerId) {
            queryApi.Get<InvoiceResponse>(`customers/${customerId}/invoice/pricemodel/year/${year}/month/${month}`)
                .Data(i => {
                    dispatchInvoice({
                        type: INVOICE_SUCCESS,
                        data: i
                    } as InvoiceAction)
                })
                .Empty(() => {
                    dispatchInvoice({
                        type: INVOICE_SUCCESS,
                        data: undefined
                    } as InvoiceAction)
                })
                .Rejected( reason => ({
                    type: INVOICE_FAILED,
                    data: undefined,
                    error: reason
                }))
                .Catch(error => ({
                    type: INVOICE_FAILED,
                    data: undefined,
                    error: error
                }))
        }
    }, [customerId, year, month])

    return(
        <Box>
            <Paper sx={{ padding: padding/2 }} className="print-invisible">
                <MonthSelector year={year} month={month} onChangeMonth={(year, month) => {setYear(year); setMonth(month)}}/>
            </Paper>
            <HorizontalSpacer size={0.5} />
            <div className="print-area">
                <Paper sx={{ padding: padding/2 }} >
                    <InvoiceHeader address={invoiceState?.data?.invoiceAddress} invoiceNumber={invoiceState?.data?.invoiceNumber} nodeName={invoiceState?.data?.nodeName}  billingPeriod={invoiceState?.data?.invoiceFor} />
                </Paper>
                <HorizontalSpacer size={0.5} />
                <Paper sx={{ padding: padding/2, overflow: 'scroll' }} >
                    <InvoiceSpecifications invoice={invoiceState?.data} />
                </Paper>
            </div>
        </Box>
    )
}

export const MonthSelector = ({year, month, onChangeMonth = (year, month) => {}}:{year:number, month:number, onChangeMonth?: (year:number, month:number) => void}) => {
    const currentMonth = DateTime.fromObject({year:year, month:month})
    const prevMonth = currentMonth.minus({month:1})
    const nextMonth = currentMonth.plus({month:1})
    const dateFormat = 'yyyy MMMM'

    return (
        <div>
            <span style={{display:"inline-block", cursor:"pointer"}} onClick={() => onChangeMonth(prevMonth.year, prevMonth.month)}>&lt;&lt;</span>
            <span style={{width:"150px", display:"inline-block", textAlign:"center"}}>{currentMonth.toFormat(dateFormat)}</span>
            <span style={{display:"inline-block", cursor:"pointer"}} onClick={() => onChangeMonth(nextMonth.year, nextMonth.month)}>&gt;&gt;</span>
        </div>
    )
}