import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { IDataItem } from "./types";
import { getAllKeys } from "../../../functions";
import { connect } from "react-redux";
import { StoreState } from "../../../state/configureStore";
import { DimensionsReducer } from "../../../state/reducers/app";

const colors: Array<string> = [
  "#01204E",
  "#028391",
  "#F6DCAC",
  "#FEAE6F",
  "#f9b4ab",
  "#264e70",
  "#679186",
  "#bbd4ce",
];

export type IColorMap = { [key: string]: string }




const CustomLegend = ({ payload, colorMap, customOrder }) => {

    return (
      <ul style={{ listStyle: 'none', padding: 0 }}>
      {customOrder.slice().reverse().map((key, index) => { // Reverse the order using slice() to avoid mutating the original
          const entry = payload.find(item => item.value === key);
          if (!entry) return null; // Skip if no entry found

          return (
              <li key={`legend-item-${index}`} style={{ display: 'flex', alignItems: 'center' }}>
                  <span
                      style={{
                          width: 12,
                          height: 12,
                          backgroundColor: colorMap[key],
                          borderRadius: '50%',
                          marginRight: 5,
                      }}
                  />
                  {key}
              </li>
          );
      })}
  </ul>
  );
};

const BarChartView = ({
  hideLegend,
  useCostomLEgend,
  items,
  formatX = (s) => s,
  dimensions,
  handleClick,
  colorMap,
  shouldShowGraphLabel
}: {
  hideLegend?: boolean
  useCostomLEgend?: boolean
  items: Array<IDataItem>;
  formatX?: (name: string) => string;
  dimensions: DimensionsReducer;
  handleClick?: (e) => void;
  colorMap?: IColorMap;
  shouldShowGraphLabel?: boolean;
}) => {
  const allStacks = getAllKeys(items, ["name", "Total"]);
  const isMobile = dimensions.device !== "desktop";
  const legendOrder =  allStacks;

  console.error('items', items)

  return (
    <ResponsiveContainer width="100%" height="100%">
        <BarChart
            data={items}
            margin={
                isMobile
                    ? { top: 20, right: 0, left: 0, bottom: 5 }
                    : { top: 20, right: 30, left: 20, bottom: 5 }
            }
        >
            <CartesianGrid stroke={"#ccc"} strokeDasharray="3" />
            <XAxis dataKey="name" tickFormatter={formatX} />
            <YAxis />
            <Tooltip />
            {!hideLegend &&
            <Legend
                layout={isMobile ? "horizontal" : "vertical"}
                verticalAlign="top"
                align={isMobile ? "left" : "right"}
                wrapperStyle={{
                    paddingLeft: 20,
                    paddingBottom: isMobile ? 20 : "auto",
                }}
                content={ useCostomLEgend ? <CustomLegend payload={items} colorMap={colorMap} customOrder={legendOrder}/> : undefined}
            />
            }

            {allStacks.map((r, index) => (
                <Bar
                    onClick={(event) =>
                        handleClick ? handleClick(event) : console.warn("Not implemented")
                    }
                    key={index}
                    dataKey={r} 
                    stackId="a"
                    fill={colorMap ? colorMap[r] ?? colors[index] : colors[index]} 
                >
                {
                  shouldShowGraphLabel 
                  ?
                    index === allStacks.length - 1 && (
                      <LabelList 
                        dataKey="Total" 
                        position="top" 
                        fill="black"
                        fontSize={14} 
                        fontWeight="bold" 
                      />
                    ) 
                  : null
                }
                </Bar>
            ))}
        </BarChart>
    </ResponsiveContainer>
  );
};

const mapStateToProps = (state: StoreState) => ({
  dimensions: state.dimensions,
});

export default connect(mapStateToProps)(BarChartView);
