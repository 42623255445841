import { Box, Paper } from "@mui/material";
import React from "react";
import { ReactNode } from "react";
import { padding } from "../../../theme";

export const PageSection = ({children}:{children?:ReactNode|undefined}) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            }}>
            <Paper sx={{
                padding: padding * 0.5,
                paddingTop: padding * 0.75,
                paddingBottom: padding * 0.5,
                marginBottom: padding * 0.6,
            }}>
                {children}
            </Paper>
        </Box>
    )
}