import React, { ReactNode } from "react"
import { NodeSummary } from "./types"
import { DecimalRounded } from "../../../components/atoms/text"
import { PropertyView } from "../../../components/molecules/properties-view"
import { padding } from "../../../theme"
import { Box, Typography } from "@mui/material"

const style = {
    infoSection: {
      paddingBottom: padding * 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      gap: 1,
    },
    paddingTop: {
      paddingTop: padding * 2
    },
    infoRow: {
      display: 'flex',
      flexDirection: 'row' as 'row',
      gap: 5,
      width: '30%',
      justifyContent: 'space-between'
    }
  }

  const PropertyRow = ({label, value}:{label:string, value:ReactNode|undefined}) => 
    <Box style={style.infoRow}>
        <Typography style={{ flex: 1 }} variant='body1'>{label}</Typography>
        <div style={{ flex: 1}} >
            <Typography variant='body1'>{value}</Typography>
        </div>
    </Box>

  

export const NodeSummaryView = ({node}:{node: NodeSummary | undefined}) => {
    return (
        <Box sx={style.infoSection}>
            <PropertyRow label="Period: " value={node?.startPeriod + ' - ' + node?.endPeriod} />
            <PropertyRow label="Stores: " value={`${node?.nodesTotal} (${node?.nodesPeriod})`} />
            <PropertyRow label="Hubs: " value={`${node?.machinesTotal} (${node?.machinesPeriod})`} />
            <PropertyRow label="Treatments: " value={<>{node?.treatmentsPeriod} (<DecimalRounded value={node?.treatmentsPerDay} />)</>} />
        </Box>
    )
}