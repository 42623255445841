import { takeEvery, put } from 'redux-saga/effects'
import { postUpdatedMachineName } from '../../utils/commands'
import { fetchMachines, fetchMachineLogs, fetchOrphanedMachines, fetchMachineOnlineHistory, fetchMachinesWithAlertTypes, PostUtils } from '../../utils/request'
import { FETCH_MACHINES, FETCH_MACHINES_WITH_ALERT_TYPES, FETCH_MACHINE_LOGS, FETCH_MACHINE_ONLINE_HISTORY, FETCH_ORPHANED_MACHINES, RENAME_MACHINE, START_TEST_SEQUENCE } from '../actionTypes/machine'


export function* startTestSequenceSaga(action: { type: typeof START_TEST_SEQUENCE; serialId: string }) {
  try {
    yield PostUtils<void>(`device/test-sequence/now?deviceId=${action.serialId}`, undefined );
    
    yield put({ type: 'TEST_SEQUENCE_STARTED', serialId: action.serialId });
  } catch (error) {
    yield put({ 
      type: 'STARTING_TEST_SEQUENCE_FAILED', 
      error: error instanceof Error ? error.message : 'Failed to start test sequence' 
    });
  }
}

export function* machineSagas() {
  yield takeEvery(FETCH_MACHINES, fetchMachines)
  yield takeEvery(FETCH_MACHINES_WITH_ALERT_TYPES, fetchMachinesWithAlertTypes)
  yield takeEvery(FETCH_MACHINE_ONLINE_HISTORY, fetchMachineOnlineHistory)
  // yield takeEvery(FETCH_MACHINE_TWIN, fetchMachineTwin)
  yield takeEvery(FETCH_MACHINE_LOGS, fetchMachineLogs)
  yield takeEvery(FETCH_ORPHANED_MACHINES, fetchOrphanedMachines)
  yield takeEvery(RENAME_MACHINE, postUpdatedMachineName)
  yield takeEvery(START_TEST_SEQUENCE, startTestSequenceSaga)
}